import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import Layout from '../components/Layout';

const PreviewPage = () => {
  return (
    <Layout>
      <p>Loading</p>
    </Layout>
  );
};

export default withPrismicPreviewResolver(PreviewPage);
